<template>
    <span>
        <a target="_blank" :href="'tel:'+row.item.phone">{{ row.item.phone }}</a>
    </span>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {

    },
}
</script>